/**
 * MG 2020-05-18
 * This file contains all text labels used in the front end.
 * It could be modified to read the values from the database if desired.
 * Last update: 2020-06-10
 */

export const lang = {
  jp: {
    wallpaper_dl_btn: "結果壁紙ダウンロード",
    qr_manual_input: "認証ID手動入力",
    score: "点数",
    profile: "心理プロファイル",
    wallpaper_dl_header: "壁紙DL",
    option_description: "回答説明",
    download: "ダウンロード",
    qr_code_skipped: "QRコードをスキップしました。",
    go_to_portal: "ポータル画面へ",
    qr_guest_id: "ゲストID",
    skip_qr_camera: "手動入力",
    button_ok: "了解",
    wallpaper: "壁紙",
    activate_camera: "QRカメラ起動",
    logo: "適性心理検査",
    wp_download: "壁紙ダウンロード",
    test_complete: "テスト完了",
    resolution_select: "解像度選択",
    to_next_question: "次へ",
    tests_completed:
      "すべての心理テストが完了しました。結果を送信してください。",
    qr_code_invalid: "QRコードが無効です",
    manual_input_invalid: "認証IDが無効です",
    qr_error: "エラー",
    qr_code_expired: "QRコードの有効期限が切れています。",
    manual_input_expired: "認証IDの有効期限が切れています。",
    scanner_error: "カメラのアクセス許可がされているか設定を確認してください。",
    error: "エラー",
    touch_screen: "画面をタッチしてください",
    touch_btn: "ボタンを押してください",
    admin_manual_input: "管理者コードを入力してください",
    admin_error: "管理者コードが違います",
    manual_input_zenkaku: "入力内容に不備があります",
  },
  en: {
    wallpaper_dl_btn: "Download wallpaper",
    qr_manual_input: "認証ID手動入力",
    score: "Score",
    profile: "Personality profile",
    wallpaper_dl_header: "Wallpaper download",
    option_description: "Answer analysis",
    download: "Download",
    qr_code_skipped: "Skipped QR code。",
    go_to_portal: "Enter",
    qr_guest_id: "Guest ID",
    skip_qr_camera: "Manual input",
    button_ok: "OK",
    wallpaper: "Wallpaper",
    activate_camera: "Activate QR Camera",
    logo: "Aptitude Test",
    wp_download: "Download wallpaper",
    test_complete: "Test completed",
    resolution_select: "Resolution",
    to_next_question: "Continue",
    tests_completed:
      "Testing  completed. Please upload evaluation result to continue.",
    qr_code_invalid: "Invalid QR code.",
    manual_input_invalid: "認証IDが無効です",
    qr_error: "エラー",
    qr_code_expired: "QR code is expired.",
    manual_input_expired: "認証IDの有効期限が切れています。",
    scanner_error: "Check the settings to see if the camera has permission to be accessed.",
    error: "Error",
    touch_screen: "Please touch the screen",
    touch_btn: "Please push the button",
    admin_manual_input: "管理者コードを入力してください",
    admin_error: "管理者コードが違います",
    manual_input_zenkaku: "入力内容に不備があります",
  },
  sv: {
    wallpaper_dl_btn: "Ladda ner bakgrundsbild",
    qr_manual_input: "Manuell inmatning",
    score: "Resultat",
    profile: "Lämplighetsprofil",
    wallpaper_dl_header: "Nedladdning av bakgrundsbild",
    option_description: "Svarsanalys",
    download: "Ladda ner",
    qr_code_skipped: "Hoppade över QR kod",
    go_to_portal: "Starta",
    qr_guest_id: "Gäst-ID",
    skip_qr_camera: "Manuell inmatning",
    button_ok: "OK",
    wallpaper: "Bakgrundsbild",
    activate_camera: "Aktivera QR-kamera",
    logo: "Lämplighetstest för Evangelion-piloter",
    wp_download: "Ladda ner bakgrundsbild",
    test_complete: "Test avklarat",
    resolution_select: "Välj upplösning",
    to_next_question: "Nästa fråga",
    tests_completed:
      "Alla test slutförda. Var god ladda upp ditt utvärderingsresultat.",
    qr_code_invalid: "Ogiltig QR-kod.",
    manual_input_invalid: "認証IDが無効です",
    qr_error: "QR-fel",
    qr_code_expired: "Utgången QR-kod",
    manual_input_expired: "認証IDの有効期限が切れています。",
    scanner_error: "Scannerfel.\nVar god ladda om sidan och försök igen.",
    error: "Ett fel har uppstått",
    touch_screen: "画面をタッチしてください",
    touch_btn: "ボタンを押してください",
    admin_manual_input: "管理者コードを入力してください",
    admin_error: "管理者コードが違います",
    manual_input_zenkaku: "入力内容に不備があります",
  },
  zh: {
    wallpaper_dl_btn: "結果壁紙ダウンロード",
    qr_manual_input: "認証ID手動入力",
    score: "点数",
    profile: "心理プロファイル",
    wallpaper_dl_header: "壁紙DL",
    option_description: "回答説明",
    download: "ダウンロード",
    qr_code_skipped: "QRコードをスキップしました。",
    go_to_portal: "ポータル画面へ",
    qr_guest_id: "ゲストID",
    skip_qr_camera: "手動入力",
    button_ok: "了解",
    wallpaper: "壁紙",
    activate_camera: "QRカメラ起動",
    logo: "适应性心理测试",
    wp_download: "壁紙ダウンロード",
    test_complete: "テスト完了",
    resolution_select: "分辨率选择",
    to_next_question: "次へ",
    tests_completed:
      "已完所有心理测试。请发送测试结果。",
    qr_code_invalid: "QR代码无效",
    manual_input_invalid: "認証IDが無効です",
    qr_error: "エラー",
    qr_code_expired: "QR码过期",
    manual_input_expired: "認証IDの有効期限が切れています。",
    scanner_error: "请确认相机是否被允许访问。",
    error: "エラー",
    touch_screen: "请触摸画面",
    touch_btn: "请按按钮",
    admin_manual_input: "管理者コードを入力してください",
    admin_error: "管理者コードが違います",
    manual_input_zenkaku: "入力内容に不備があります",
  },
};
