import React, { Component } from "react"; // import react and the Component class
import ReactDOM from "react-dom"; // required for rendering to a webpage
import "./assets/style.css"; // import the stylesheet
import QuestionBox from "./components/QuestionBox"; // the component for displaying a question
//import TestSelectBox from "./components/TestSelectBox";
import QrReader from "react-qr-reader"; /** NOTE: THe quiz app MUST be hosted/served via SSL/HTTPS for the QR reader to work.　
                                        Modern browsers do not allow access to the device's camera without encryption (i.e. SSL/HTTPS) */
import axios from "axios";
import * as labels from "./assets/labels.js"; /* Text labels for buttons, message prompts, etc, in different languages. */
import * as images from "./assets/images.js"; /* Non-css images for buttons, banners, etc in different languages. */
import WallpaperDL from "./components/WallpaperDL"; /* the component for displaying the wallpaper download page */
import PopUp from "./components/PopUp"; /* the component used for all the pop-up windows in the app */

class EvaAptitudeTest extends Component {
  /******** SETTINGS *********/
  /* This is the path (or URL) to where the php files for fetching and storing data in the database are located.
    If those files are not hosted in the same directory as the react files (or on a whole other server even)
    it is necessary to change this property.
    Default is "cgi", meaning that react expects the php files to be in a subfolder called cgi relative to the index.html file after the app is built.
    NOTE: If using a remote server, the files must be accessed using SSL/HTTPS in order to avoid blocking of mixed active content,
    see https://developer.mozilla.org/en-US/docs/Web/Security/Mixed_content/How_to_fix_website_with_mixed_content
    https://quizapp.api/ */

  //pathToAPI = "https://quizapp.api/quizapp/cgi"; // local dev test server
  pathToAPI = "cgi"; // live and test server

  /**
   * DONT FORGET TO CHANGE THE   "homepage"  PARAMETER IN package.json WHEN BUILDING THE APP
   * For live server it should be:   "homepage": "/",
   * For (my) local dev and test server it should be:  "homepage": "/quizapp",
   */

  numberOfTests = 4; /** The number of tests to display to the user */
  questionsPerTest = 1; /** The number of questions per test. */
  shuffleQuestions = true; /** Assign questions to test in a random order and category. */
  qrReadInterval = 300; // time to wait inbetween taking pictures with the QR reader (milliseconds)

  /************/

  // the state should always be located at the nearest parent. this is where all State variables are declared.
  // everytime a state variable is changed using setState the page is rebuilt to reflect the new state.
  state = {
    currentTestIndex: -1, // the array index of the current test
    currentTestId: -1, // test id as registered in the database
    testsCompleted: 0, // Counter to check how many tests the user have completed. Used for disabling completed test, marking the next test as available, etc.
    qrGuestId: "", // the raw data read by the qr-reader
    qrGuestIdOK: false, // flag to check if the data from the qr reader was acceptable
    sessionData: {}, // this holds the tests, questions, options and answers for the currently active user
    reSession: false, // used to check if we have resumed a previous session, i.e. user have logged in again after previously completing one or more tests
    currentQuestionIndex: 0, // ephemeral index for currently active question
    showWallpaper: false, // whether to show the wallpaper screen or not
    cameraActive: false, // whether or not the camera is active
    lang: "jp", // the langauge to use for labels.js, images.js and the css-classes
    debug: false, //debug mode, if true the manual input button and option score will be shown
  };

  /* Reset states to initial values */
  resetState = () => {
    this.setState({
      currentTestIndex: -1, // the array index of the current test
      currentTestId: -1, // test id as registered in the database
      testsCompleted: 0,
      qrGuestId: "",
      qrGuestIdOK: false,
      sessionData: {},
      reSession: false,
      currentQuestionIndex: 0,
      showWallpaper: false,
      cameraActive: false,
      debug: false,
    });
  };

  /*長押しイベントの設定*/
  constructor() {
    super()
    this.handleButtonPress = this.handleButtonPress.bind(this)
    this.handleButtonRelease = this.handleButtonRelease.bind(this)
  }

  /** Called when user clicks OK in the popup after selecting an option in the QuestionBox phase */
  optionSelect = (optionId, value, testId, testIndex) => {
    var nextQindex = this.state.currentQuestionIndex + 1;
    var nexTestComp = this.state.testsCompleted;
    var curTestIndex = this.state.currentTestIndex;
    var nextTestId;

    /** If we have run out of questions for this test. Reset states. Increase completed tests by 1. */
    if (
      nextQindex >= this.state.sessionData["tests"][testIndex].questions.length
    ) {
      nextQindex = 0;
      nexTestComp = this.state.testsCompleted + 1;

      /* MG 2020-06-05 specification update: 
        because we want to progress automatically to the next test, 
        we increase currentTestIndex by 1 instead of resetting it. 
        And then we load the next test automatically and register it as started. */
      curTestIndex += 1;

      /* Check if there are more tests. */
      if (curTestIndex < this.state.sessionData["tests"].length) {
        nextTestId = this.state.sessionData["tests"][curTestIndex].test_data
          .test_id;
        this.selectTest(curTestIndex, nextTestId);
      } else {
        /* If we are out of tests. Set state to result upload screen. */
        this.setState({
          testsCompleted: nexTestComp, // increase number of completed tests by 1
          currentQuestionIndex: nextQindex,
          currentTestIndex: curTestIndex, // script index from iterating over the four loaded tests (0-3)
        });
      }
    } else {
      /** If there are more questions in this test, the next one will follow automatically upon clicking OK in the popup. 
      So we have to automatically register it as started. */
      this.registerQuestionStart(
        testId,
        this.getQuestionIdByOrder(testId, nextQindex)
      );
    }

    this.setState({
      testsCompleted: nexTestComp, // increase number of completed tests by 1
      currentQuestionIndex: nextQindex,
      currentTestIndex: curTestIndex, // script index from iterating over the four loaded tests (0-3)
    });
  };

  /** Called when user clicks a test in the TestSelect phase. Sets state and registers the selected test as "started, but not finished" in the database. */
  selectTest(testIndex, testId) {
    this.setState({
      currentTestIndex: testIndex,
    });

    this.registerQuestionStart(
      testId,
      this.state.sessionData["tests"][testIndex].questions[
        this.state.currentQuestionIndex
      ].question_id
    ); // register that user started this test/question
  }

  /* This is run every 500 ms to read the input from the camera used for QR code reading */
  handleQRScan = (data) => {
    if (data) {
      /** Register a new login for this QR code */
      this.startNewUserSession(data, false);
    }
  };

  /** Register a new login by a user */
  startNewUserSession = (qr_data, ismanual) => {
    /** Object for returning sessions data */
    var sessionData = { user_id: -1, tests: [], questions: [], answers: [] };

    /* Set qrGuestId to error as default. */
    var qrGuestId = -400;

    /* Validate QR code data */
    const qrValidationUrl = this.pathToAPI + "/validate_qr.php?u=" + qr_data;
    //console.log(qrValidationUrl);
    axios
      .get(qrValidationUrl)
      .then((response) => {
        qrGuestId = response.data;

        /* error code -400, invalid QR code. show error popup. */
        if (qrGuestId === -400 && ismanual == false) {
          this.showPopup(
            labels.lang[this.state.lang].qr_error,
            labels.lang[this.state.lang].qr_code_invalid,
            ""
          );
          return;
        }
        else if (qrGuestId === -400 && ismanual == true) {
          this.showPopup(
              labels.lang[this.state.lang].qr_error,
              labels.lang[this.state.lang].manual_input_invalid,
              ""
          );
          return;
        }

        /* URL for creating a new login entry */
        const startUserSessionUrl =
          this.pathToAPI +
          "/start_user_session.php?u=" +
          qrGuestId +
          "&nt=" +
          this.numberOfTests +
          "&nq=" +
          this.questionsPerTest +
          "&s=" +
          this.shuffleQuestions +
          "&l=" +
          this.state.lang;
        //console.log(startUserSessionUrl);
        /* Create new login entry */
        axios
          .get(startUserSessionUrl)
          .then((response) => response.data)
          .then((data) => {
            //console.log("dsadas" + data);
            /* If the QR code is expired (error code -300), display a popup to the user to inform them about it. */
            if (data === -300 && ismanual == false) {
              this.showPopup(
                labels.lang[this.state.lang].qr_error,
                labels.lang[this.state.lang].qr_code_expired,
                ""
              );
              return;
            }
            else if (qrGuestId === 300 && ismanual == true) {
              this.showPopup(
                  labels.lang[this.state.lang].qr_error,
                  labels.lang[this.state.lang].manual_input_expired,
                  ""
              );
              return;
            }
            sessionData["user_id"] = data; // store user id (guest id) in session data

            /** Only continue fetching user data if there were no errors while getting user id. 
            "data" will be negative if there was an error. */
            if (data > 0) {
              /*  Nested fetch no1.  Fetch user assigned tests/quests */
              const getUserTestsAndQuestionsUrl =
                this.pathToAPI +
                "/get_user_tqs.php?u=" +
                qrGuestId +
                "&nt=" +
                this.numberOfTests +
                "&nq=" +
                this.questionsPerTest +
                "&s=" +
                this.shuffleQuestions +
                "&l=" +
                this.state.lang;
              // console.log(getUserTestsAndQuestionsUrl);
              axios
                .get(getUserTestsAndQuestionsUrl)
                .then((response) => response.data)
                .then((data) => {
                  //console.log(data);
                  //console.log("user data: " + JSON.stringify(data));
                  /** Store user's assigned tests and question.
                   * Each test and question is encapsulated in an array to preserve the ordering,
                   * so we have to extract them from their arrays.
                   * I apologize for the mess. Blame it on javascript not preserving the order of attributes in objects when using JSON. */
                  for (var i = 0; i < data["tests"].length; i++) {
                    sessionData["tests"][i] = { test_data: {}, questions: [] };
                    //console.log(data["tests"][i][0]["test_data"]);
                    sessionData["tests"][i]["test_data"] =
                      data["tests"][i][0]["test_data"];
                    for (
                      var j = 0;
                      j < data["tests"][i][0]["questions"].length;
                      j++
                    ) {
                      //console.log(data["tests"][i][0]["questions"]);
                      sessionData["tests"][i]["questions"] =
                        data["tests"][i][0]["questions"][j];
                    }
                  }
                  //console.log(sessionData["tests"]);

                  /* Nested fetch no2. Fetch answered/unfinished questions if any */
                  const getUserAnswersUrl =
                    this.pathToAPI + "/get_user_answers.php?u=" + qrGuestId;
                  //console.log(getUserAnswersUrl);
                  axios
                    .get(getUserAnswersUrl)
                    .then((response) => response.data)
                    .then((data) => {
                      // console.log("user answers: " + JSON.stringify(data));
                      sessionData[
                        "answers"
                      ] = data; /** Store user's answers (if any) */

                      /* If data was found, use it to update the state of the app */
                      this.setState({
                        sessionData: sessionData,
                        testsCompleted: Math.floor(
                          sessionData["answers"].length / this.questionsPerTest
                        ), // the integer part of this will be the number for completed tests
                        currentQuestionIndex:
                          sessionData["answers"].length % this.questionsPerTest, // the remainder will be the number of completed questions of any unfinished test
                        qrGuestId: qrGuestId,
                        qrGuestIdOK: true,
                        reSession: sessionData["answers"].length > 0,
                        //showWallpaper: sessionData["answers"].length > 3, // if user has four or more answers in the database, go straight to wallpaper download
                      });
                    })
                    .catch((err) => {
                      console.log(err.code);
                      console.log(err.message);
                      console.log(err.stack);
                    });
                })
                .catch((err) => {
                  console.log(err.code);
                  console.log(err.message);
                  console.log(err.stack);
                });
            } else {
              //alert("Invalid QR code.");
              this.showPopup(
                labels.lang[this.state.lang].qr_error,
                labels.lang[this.state.lang].qr_code_invalid,
                ""
              );
              return;
            }
          })
          .catch((err) => {
            console.log(err.code);
            console.log(err.message);
            console.log(err.stack);
          });
      })
      .catch((err) => {
        console.log(err.code);
        console.log(err.message);
        console.log(err.stack);
      });

    // console.log("qrGuestId " + JSON.stringify(qrGuestId));
    /* If the QR code validation returns error code -400 ($_USER_STATUS_INVALID_QR_DATA), just stop the script. */
    if (qrGuestId === -400) {
      //this.showPopup("qr code error400", "QR CODE ERROR400", "CLOSE");
      return;
    }

    return sessionData;
  };

  /**
   * MG 2020-06-12
   * Get option value.
   * Needed because option score value can now be random as of 2020-06-11 spec.
   * They are randomly assigned on the backend side so that all values per question are unique
   */
  getOptionValue = (option_id) => {
    var value = 0;
    valueLoop: for (
      var t = 0;
      t < this.state.sessionData["tests"].length;
      t++
    ) {
      var test = this.state.sessionData["tests"][t];
      for (var q = 0; q < test.questions.length; q++) {
        var question = test.questions[q];
        for (var i = 0; i < question.question_options.length; i++) {
          if (question.question_options[i].option_id === option_id) {
            value = question.question_options[i].option_value;
            break valueLoop;
          }
        }
      }
    }
    return value;
  };

  /** Register an answer by a user. Called when user clicks an option in the QuestionBox phase.
   *  state; 0 or 1, 0 = test started but no option selected, 1 = option selected
   *  test_id; the internal db id for the test
   *  option_id; the internal db id for the selected option
   */
  registerOptionSelect = (test_id, question_id, option_id) => {
    const url =
      this.pathToAPI +
      "/set_user_answer.php?u=" +
      this.state.sessionData["user_id"] +
      "&t=" +
      test_id +
      "&q=" +
      question_id +
      "&o=" +
      option_id +
      "&v=" +
      this.getOptionValue(option_id) +
      "&s=2"; // 1 = question started but not answered yet, 2 = option selected and question answered.
    // console.log("Registering option select: " + url);
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        // console.log("Got reply: " + data);
      });
  };

  /** Register a test/question being selected by a user
   *  state; 0 or 1, 0 = test/question started but no option selected, 1 = option selected
   *  test_id; the internal db id for the test
   *  question_id; the internal db id for the question
   */
  registerQuestionStart = (test_id, question_id) => {
    const url =
      this.pathToAPI +
      "/set_user_answer.php?u=" +
      this.state.sessionData["user_id"] +
      "&t=" +
      test_id +
      "&q=" +
      question_id +
      "&s=1"; // state 0 = test selected, 1=question started but not answered yet, 2 = option selected and answered.
    // console.log("Registering question start: " + url);
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        // console.log("Got reply: " + data);
      });

    /* After starting a question, reset the reSession variable so that the loading animation will play in the WallpaperDL screen. */
    this.setState({
      reSession: false,
    });
  };

  /** Get the question_id of the n:th question in the question array for test with test id test_id */
  getQuestionIdByOrder = (test_id, n) => {
    var qId;
    this.state.sessionData["tests"].forEach((test) => {
      if (test.test_data.test_id === test_id) {
        qId = test.questions[n].question_id;
      }
    });
    return qId;
  };

  /* In case of error when reading QR code */
  handleQRError = (err) => {
    this.showPopup(
      labels.lang[this.state.lang].error,
      labels.lang[this.state.lang].scanner_error,
      ""
    );
    console.error(err);
  };

  /** If user skips QR code. For dev/debugging purposes only. Should not be skippable in production code. */
  skipQRCode = () => {
    /*2020-08-04 スタッフの人しかこの機能を利用しない予定なので日本語だけでOK*/
    var adminInput = prompt(labels.lang[this.state.lang].admin_manual_input, "");

    /*キャンセルボタンを押した場合は何もしない*/
    if(adminInput === null){
        return;
    }

    if(adminInput !== "admin"){
        this.showPopup(
            labels.lang[this.state.lang].qr_error,
            labels.lang[this.state.lang].admin_error,
            ""
        );
        return;
    }

    var qrInput = adminInput + ",";
    
    var manualInput = prompt(labels.lang[this.state.lang].qr_manual_input, "");

    /*半角英数字記号以外は受け付けない*/
    var result = /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/.test(manualInput);
    if(!result){
        this.showPopup(
            labels.lang[this.state.lang].qr_error,
            labels.lang[this.state.lang].manual_input_zenkaku,
            ""
        );
        return;
    }

    //qrInput = labels.lang[this.state.lang].qr_code_skipped;
    if (manualInput !== "" && manualInput !== null) qrInput = qrInput + manualInput;
    console.log(qrInput);
    this.startNewUserSession(qrInput, true); /** Register new login by user */
  };

  /**
   * Set state so the app loads the wallpaper screen, formerly the result screen
   * */
  getTestResult = () => {
    this.setState({
      showWallpaper: true, // result screen is no more. go straight to wallpapers.
    });
  };

  /**
   * componentDidMount() runs once after application has loaded.
   */
  componentDidMount() {
    /* Set language and debug mode from url query on application load */
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get("lang");
    const debug = urlParams.get("debug");

    /* Make sure both labels and images are defined for the selected language. Otherwise we use the default (jp). */
    if (labels.lang[lang] !== undefined && images.lang[lang] !== undefined) {
      this.setState({
        lang: lang,
      });
    }

    /* Make sure both labels and images are defined for the selected language. Otherwise we use the default (jp). */
    if (debug !== undefined && debug === "true") {
      this.setState({ debug: true });
    } else {
      this.setState({ debug: false });
    }
  }

  /**
   * Helper method used for css classes which have a background image that needs translating.
   * It will try to find a css class maching the provided language code.
   * If a css class matching the generated name does not exist, the default japanese class will automatically be used.
   *
   * @param className, the name of the class
   * @param language, the ISO language code, can be set by the url query
   */
  getLangSuffixClass(className, language) {
    let suffixes = ["jp", "en", "zh", "sv"]; // we only allow these languages. all others default to japanese.

    /* if we have an array of class names as input, attach language suffix to each one regardless. */
    if (Array.isArray(className)) {
      var classes = "";
      for (var i = 0; i < className.length; i++) {
        if (suffixes.includes(language)) {
          classes += className[i] + " " + className[i] + "-" + language;
        } else {
          classes += className[i];
        }
        classes += " "; // add a space between class names
      }
      return classes;
    } else {
      if (suffixes.includes(language)) {
        return className + " " + className + "-" + language;
      } else {
        return className;
      }
    }
  }

  /* Helper method for displaying popups for errors, etc. */
  showPopup(title_text, body_text, button_text) {
    ReactDOM.render(
      <PopUp
        modal_title={title_text}
        modal_body={body_text}
        button_label={button_text}
        onClickOK={() => {}}
        button_classes="btn btn-skinned btn-back"
      />,
      document.getElementById("popup")
    );
  }

  /*押したときにタイマーを発動して一定時間後にログインポップアップ表示*/
  handleButtonPress () {
    this.buttonPressTimer = setTimeout(() => {this.skipQRCode()}, 5000);
  }

  /*離した際にタイマーを解除する*/
  handleButtonRelease () {
    clearTimeout(this.buttonPressTimer);
  }

  /* the render method returns a JSX template that will be rendered on the page */
  render() {
    //var btnClasses;
    //var megastampClasses;

    var megaStampAnimDelay = 0.6; // default delay for mega stamp animation
    if (this.state.reSession) {
      megaStampAnimDelay = 1.1; // nullify animation delay if this is not a resumed session
    }

    var megaStampStyle = {
      animationDelay: megaStampAnimDelay + "s",
    };

    return (
      <div>
        {/* The top border always rendered regardless of state. Only its content is state-dependant. It is fixed and always on top. */}
        <div className="top-border logo-container">
          <img
            className="site-loader-animation"
            src={images.lang[this.state.lang].logo}
            alt={labels.lang[this.state.lang].logo}
          ></img>
        </div>
            
        {/* Begin main container. */}
        <div
          id="main-container"
          className="main-container container smartphone site-loader"
        >
          {/* Main container contains all different states the app can be in and renders components accordingly. */}

          {/* If no QR code data was found, activate camera. */}
          {!this.state.showResultScreen &&
            this.state.qrGuestId === "" &&
            this.state.qrGuestIdOK === false && (
              <div className="page-loader">
                <div className="col-12 vertical-middle-box">
                  {this.state.cameraActive && (
                    <div className="camera-container camera-active">
                      <div className="camera-inner">
                        <QrReader
                          delay={this.qrReadInterval}
                          onError={this.handleQRError}
                          onScan={this.handleQRScan}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  )}
                  {!this.state.cameraActive && (
                    <div
                      className={this.getLangSuffixClass(
                        ["camera-container", " site-loader-animation"],
                        this.state.lang
                      )}
                    >
                      <div className="camera-inner"></div>
                    </div>
                  )}
                </div>
                {!this.state.cameraActive && (
                    <div className="col-12 button-container vertical-bottom-box site-loader-animation">
                        <button
                          className={this.getLangSuffixClass(
                            ["btn", "btn-skinned", "btn-activate-qr"],
                            this.state.lang
                          )}
                          onClick={() => {
                            this.setState({
                              cameraActive: true, //activate camera
                            });
                          }}
                        >
                          &nbsp;
                        </button>
                        {/* Comment out the code below to remove the manual input button */}
                        {/*this.state.debug === true && (
                          <button
                            className="btn skip-qr-btn eva-btn"
                            onClick={this.skipQRCode}
                          >
                            {labels.lang[this.state.lang].skip_qr_camera}
                          </button>
                                  )*/}
                        {/* Comment out the code above to remove the manual input button */}
                    </div>
                )}
                {/*QRコードでログインできない場合の隠しログインポップアップ表示ボタン*/}
                <div
                    className="hidden-login-btn"
                    onTouchStart={this.handleButtonPress}
                    onTouchEnd={this.handleButtonRelease}
                    onMouseDown={this.handleButtonPress}
                    onMouseUp={this.handleButtonRelease}
                    onMouseLeave={this.handleButtonRelease}>
                 </div>
              </div>
            )}

          {/* If we have qr code data and no test is selected; display test start button. */}
          {!this.state.showWallpaper &&
            this.state.qrGuestIdOK &&
            this.state.currentTestIndex < 0 &&
            this.state.testsCompleted <
              this.state.sessionData["tests"].length && (
              <div className="page-loader" style={{ textAlign: "center" }}>
                <div className="col-12 vertical-middle-box start-button-box">
                  <button
                    className={this.getLangSuffixClass(
                      ["btn", "btn-start-test"],
                      this.state.lang
                    )}
                    onClick={() => {
                      let nextTest = this.state.testsCompleted; // start at question with index nextTest depending on how many answered tests/questions we found in the answers table in the database
                      if (nextTest < this.state.sessionData["tests"].length) {
                        this.selectTest(
                          nextTest,
                          this.state.sessionData["tests"][nextTest].test_data
                            .test_id
                        );
                      }
                    }}
                  >
                  </button>
                </div>

                {/*画面をタッチしてください表示*/}
                <span className="touch_screen_str">
                    {labels.lang[this.state.lang].touch_screen}
                </span>
              </div>
            )}

          {/* If we have qr code data and all tests are completed, show send result button */}
          {!this.state.showWallpaper &&
            this.state.qrGuestIdOK &&
            this.state.testsCompleted >=
              this.state.sessionData["tests"].length && (
              <div className="page-loader" style={{ textAlign: "center" }}>
                <div className="question-box vertical-middle-box tests-completed-box">
                  <img
                    src={images.lang[this.state.lang].tests_completed}
                    alt={labels.lang[this.state.lang].tests_completed}
                    className="tests-completed"
                  />
                  <button
                    className={this.getLangSuffixClass(
                      [
                        "btn",
                        "btn-available",
                        "btn-skinned",
                        "btn-show-result",
                      ],
                      this.state.lang
                    )}
                    onClick={this.getTestResult}
                    style={
                      megaStampStyle
                    } /* Re-use the megaStampStyle to set a delay to the button animation */
                    disabled={
                      this.state.testsCompleted >=
                        this.state.sessionData["tests"].length &&
                      this.state.sessionData["tests"].length > 0
                        ? ""
                        : "disabled"
                    }
                  >
                    &nbsp;
                  </button>
                </div>

                {/*ボタンを押してください表示*/}
                <span className="touch_screen_str">
                    {labels.lang[this.state.lang].touch_btn}
                </span>
              </div>
            )}

          {/* IF a test is selected (aka currentTestIndex is not 0), display question screen. */}
          {!this.state.showWallpaper &&
            this.state.qrGuestId !== "" &&
            this.state.qrGuestIdOK &&
            this.state.currentTestIndex >= 0 &&
            this.state.currentTestIndex <
              this.state.sessionData["tests"].length && (
              <div className="row page-loader">
                {
                  <QuestionBox
                    question_text={
                      this.state.sessionData["tests"][
                        this.state.currentTestIndex
                      ].questions[this.state.currentQuestionIndex].question_text
                    } // the question text
                    question_image_url={
                      this.state.sessionData["tests"][
                        this.state.currentTestIndex
                      ].questions[this.state.currentQuestionIndex]
                        .question_image_url
                    } // the image for this question (leave blank for no image)
                    question_description={
                      this.state.sessionData["tests"][
                        this.state.currentTestIndex
                      ].questions[this.state.currentQuestionIndex]
                        .question_description
                    } // the question description
                    aOptions={
                      this.state.sessionData["tests"][
                        this.state.currentTestIndex
                      ].questions[this.state.currentQuestionIndex]
                        .question_options
                    } // the option data for this question
                    key={
                      this.state.sessionData["tests"][
                        this.state.currentTestIndex
                      ].questions[this.state.currentQuestionIndex].question_id
                    } // unique ID, key is essential in react for rendering lists for identifying and correlating the instance of the component with its data
                    fOptionSelect={(userAnswer, option_value) =>
                      this.optionSelect(
                        userAnswer,
                        option_value,
                        this.state.sessionData["tests"][
                          this.state.currentTestIndex
                        ].test_data.test_id,
                        this.state.currentTestIndex
                      )
                    } // fOptionSelect is a callback function that will update the state of the app, we call this when user clicks OK in the modal popup that is displayed after selecting an option.
                    fRegisterAnswer={(option_id) =>
                      this.registerOptionSelect(
                        this.state.sessionData["tests"][
                          this.state.currentTestIndex
                        ].test_data.test_id,
                        this.state.sessionData["tests"][
                          this.state.currentTestIndex
                        ].questions[this.state.currentQuestionIndex]
                          .question_id,
                        option_id
                      ) && true
                    } // fRegisterAnswer is a callback function that will register the user's selected option at the same time that the modal popup is displayed.
                    // This is because we want to save the answer to the database without updating the state of the app. The state should not be updated until the user clicks OK in the modal.
                    fClassLang={this.getLangSuffixClass}
                    //testIndex={this.state.testIndex}
                    lang={this.state.lang}
                    debug={this.state.debug}
                  />
                }
              </div>
            )}

          {/* Wallpaper download screen. */}
          {this.state.showWallpaper &&
          this.state.qrGuestId !== "" &&
          this.state.qrGuestIdOK ? (
            <div className="page-loader">
              <WallpaperDL
                exuid={this.state.qrGuestId}
                pathToAPI={this.pathToAPI}
                fNavBack={() => this.setState({ showWallpaper: false })}
                fClassLang={this.getLangSuffixClass}
                lang={this.state.lang}
                reSession={this.state.reSession}
              />
            </div>
          ) : null}
        </div>

        {/* The bottom border always rendered regardless of state. Only its content is state-dependant. */}
        <div className="bottom-border">
          {/* For now this is just used to display the QR Guest ID on the screen. */}
          {!this.state.showWallpaper &&
            this.state.qrGuestId !== "" &&
            this.state.qrGuestIdOK && (
              <div className="qr-output-container-outer">
                <div className="qr-output-container-inner">
                  <div className="qr-output">{this.state.qrGuestId}</div>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

ReactDOM.render(<EvaAptitudeTest />, document.getElementById("root"));
