import React, { useState } from "react";
import axios from "axios";
import * as labels from "../assets/labels.js"; /* Text labels for buttons, message prompts, etc. */
import * as images from "../assets/images.js"; /* Images for buttons, banners, etc in different languages. */

/* WallpaperDL lists available wallpapers to the user and they can click them to save them to their device */
const WallpaperDL = ({
  exuid,
  pathToAPI,
  fClassLang,
  lang,
  reSession,
}) => {
  /* Use states in the component so that the wallpaper list will be populated with that from the database once it is fetched. */
  const [wallpapers, setWallpapers] = useState([]);
  const [wallpapersLoaded, setWallpapersLoaded] = useState(false);
  const [defaultOption, setDefaultOption] = useState("");
  const [defaultOptionSet, setDefaultOptionSet] = useState(false);
  const [dlBtnEnabled, setDlBtnEnabled] = useState(false);

  /* The time it should take for the progressbar to load. 
    If this is a reSession we want it to load faster. */
  const progressBarDuration = reSession === true ? 750 : 5000;

  /* Fetch wallpaper data from the datbase */
  const getWallpapers = () => {
    const url = pathToAPI + "/get_wallpapers.php?exuid=" + exuid;
    //console.log("Getting wallpapers: " + url);
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        //console.log("Got reply: " + JSON.stringify(data));
        setTimeout(() => {
          setWallpapersLoaded(
            true
          ); /* must call setWallpapersLoaded() first, because if setWallpapers() is called first getWallpapers() is run again because the state has changed before we sett the flag to check if we already have wallpapers */
          setWallpapers(data);
        }, progressBarDuration);
      });
  };

  //https://stackoverflow.com/questions/6150289/how-can-i-convert-an-image-into-base64-string-using-javascript
  function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  /* Create a download prompt for the image by reading the value from the resolution selectiond dropdown list and loading that image as bas64 using react. */
  function doDownload(filename) {
    console.log("filename" + filename);

    /* get image url from the select element */
    var e = document.getElementById("wp-resolution-select");
    var url = e.options[e.selectedIndex].value;

    if (url === "") {
      return;
    }

    filename = url.split("/");
    filename = filename[filename.length - 1];

    /* Get image data in base64 */
    var img_data = "";
    toDataURL(url, function (dataUrl) {
      img_data = dataUrl;
      /* create a temporary a element that will act as our automatic downloader */
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        img_data
      ); /* this is the data for the image file in base64 */
      element.setAttribute(
        "download",
        filename
      ); /* this is the name that will be given to the image when downloaded */
      element.style.display = "none"; /* make the element invisible */
      document.body.appendChild(element); /* append it to the DOM */
      element.click(); /* simulate a click so that the download starts */
      document.body.removeChild(element); /* remove it from the DOM */
    });
  }

  /* If we have only one wallpaper, make it full width. Otherwise two per row. */
  var wallpaper_class = wallpapers.length === 1 ? "col-12" : "col-6";
  wallpaper_class += " wallpaper-preview";

  /* Load wallpapers and select default option only once! */
  if (!wallpapersLoaded) getWallpapers();
  else if (!defaultOptionSet) setSelectedOption();

  /* Get device screen resolution as string. Used to select default option when selecting what resolution wallpaper to download. */
  function getScreenResolution() {
    return window.screen.width + "x" + window.screen.height;
  }

  /* Set default selected resolution in the select element. */
  function setSelectedOption() {
    if (wallpapers[0] !== undefined && wallpapers[0] !== null) {
      var ress = JSON.parse(wallpapers[0].wallpaper_resolutions).resolutions;
      setDefaultOptionSet(
        true
      ); /* Flag that we are setting default option so we dont get stuck in a loop */
      /* First we select the option by just matching the width */
      for (let i = 0; i < ress.length; i++) {
        if (
          ress[i].resolution_name.split("x")[0] ===
          getScreenResolution().split("x")[0]
        ) {
          setDefaultOption(ress[i].image_url);
          setDlBtnEnabled(true);
          break;
        }
      }
      /* Then we select the option by matching both width and height */
      for (let j = 0; j < ress.length; j++) {
        if (ress[j].resolution_name === getScreenResolution()) {
          setDefaultOption(ress[j].image_url);
          setDlBtnEnabled(true);
          break;
        }
      }
    }
  }

  const meterstyle = {
    width: "50%",
    animationDuration: progressBarDuration / 1000 + "s",
  };

  return (
    <div className="wallpaper-result-loader">
      {/* Show the wallpaper selection screen */}
      {wallpapersLoaded && wallpapers !== undefined ? (
        wallpapers.map((wp, wpIndex) => (
          <div key={wpIndex} className="wallpaper-loader">
            <div id="wallpaper-dl-box" className="vertical-middle-box">
              <div className={wallpaper_class}>
                <img src={wp.wallpaper_url} alt={wp.wallpaper_title}></img>
                <div>
                  <select
                    id="wp-resolution-select"
                    className="wp-resolution-select"
                    defaultValue={defaultOption}
                    onChange={setDlBtnEnabled}
                  >
                    <option value="" disabled>
                      {labels.lang[lang].resolution_select}
                    </option>
                    {wp.wallpaper_resolutions !== undefined &&
                      JSON.parse(wp.wallpaper_resolutions).resolutions.map(
                        (resObj, index) => (
                          <option key={index} value={resObj.image_url}>
                            {resObj.resolution_name}
                          </option>
                        )
                      )}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-12 button-container vertical-bottom-box">
              <button
                className={fClassLang(["btn", "btn-save", "eva-btn"], lang)}
                disabled={dlBtnEnabled ? "" : "disabled"}
                onClick={doDownload.bind(this, "dl")}
              >
                &nbsp;
              </button>
              {/* <button className="btn btn-back eva-btn" onClick={fNavBack}>
                &nbsp;
              </button> */}
            </div>
          </div>
        ))
      ) : (
        <div className="progressbar-container">
          {/* Display the loading screen (UPLOADING) with the progressbar 
            before loading the actual wallpapers screen. */}
          <img
            src={images.lang[lang].uploading_upper}
            alt="Uploading"
            className="uploading-upper"
          />
          <div className="meter orange">
            <span style={meterstyle}>
              <span></span>
            </span>
          </div>
          <img
            src={images.lang[lang].uploading_lower}
            alt="Uploading"
            className="uploading-lower"
          />
        </div>
      )}
    </div>
  );
};

export default WallpaperDL;
