/**
 * QuestionBox.js
 * This component has two states:
 * 1. Display question and options
 *      Here the user is shown the question and must chose one of the options to continue.
 * 2. Display the description for the selected option
 *      After the user has chosen an option, the description for that option is shown.
 *      When user clicks the "Next" button, the app will return to index.js
 * Last update MG 2020-07-14
 */

import React, { useState } from "react"; // import react, always needs to be done in every file. also import useState so we can use the hooks API to enable states in our function components
// import * as labels from "../assets/labels.js"; /* Text labels for buttons, message prompts, etc, in different languages. */
// import * as images from "../assets/images.js"; /* Non-css images for buttons, banners, etc in different languages. */

const QuestionBox = ({
  question_text,
  question_image_url,
  question_description,
  aOptions,
  fOptionSelect,
  fRegisterAnswer,
  fClassLang,
  lang,
  debug,
}) => {
  // the "fSelected" prop is a function passed in when the QuestionBox is created
  const [optionDescription, fSetOptionDecription] = useState("");
  const [selectedOptionId, fSetOptionId] = useState("");

  /* Scroll to top of question element */
  try {
    document.getElementById("question-box").scrollTop = 0;
  } catch (e) {}

  /** Very basic and naive function for checking that we're dealing with an image file. */
  function isImage(imgUrl) {
    //console.log("imgUrl: " + imgUrl);
    var pattern = new RegExp(
      "/.(gif)|(jpg)|(jpeg)|(bmp)|(png)|(tif)|(tiff)|(pcx)+$/",
      "i"
    ); // allowed file extensions
    return pattern.test(imgUrl);
  }

  /* Decode special html characters */
  function decodeHtml(text) {
    return text
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&amp;#34;/g, '"')
      .replace(/&quot;/g, '"')
      .replace(/&amp;#39;/g, "'")
      .replace(/&#39;/g, "'")
      .replace(/&amp;/g, "&");
  }

  /* Add animatinos to the selected option button and fade out the question. */
  function setOptDescWithDelay(optId, optDesc) {
    /* Bind listener so that when animations added below (fade-out) have finished we load the answer/option description/explanation */
    document
      .getElementById("question-box")
      .addEventListener(
        "animationend",
        loadExplanation.bind(this, optDesc, optId)
      );

    var buttons = document.getElementsByTagName("button");
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].disabled = true;
    }
    /* Add animation classes */
    document.getElementById("question-box").classList.add("fade-out");
    document.getElementById(optId).classList.add("button-press-animation");
  }

  /* Load option explanation text and id for selected option and scroll into position */
  function loadExplanation(optDesc, optId) {
    fSetOptionDecription(optDesc);
    fSetOptionId(optId);

    /* Scroll to top of question element */
    try {
      document.getElementById("question-box").scrollTop = 0;
    } catch (e) {}

    /* Scroll to user's selected option */
    if (!isScrolledIntoView(document.getElementById("optDesc" + optId))) {
      setTimeout(() => {
        try {
          document.getElementById("optDesc" + optId).scrollIntoView(true);
        } catch (e) {}
      }, 450);
    }
  }

  /* Magic scrolling code start */

  function isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;

    // Only completely visible elements return true:
    var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    // Partially visible elements return true:
    // isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }
  /* Magic scrolling code end */

  function getFirstRow(text) {
    var bits = text.split("\n");
    return bits[0];
  }

  function getAllButFirstRow(text) {
    var bits = text.split("\n");
    return bits.slice(1).join("\n");
  }

  var option_letters = ["A", "B", "C", "D"];

  return (
    <div className="question-loader-animation">
      {/* Display the question content */}
      {optionDescription === "" ? (
        <div
          id="question-box"
          className="col-12 question-box vertical-middle-box"
        >
          <div className="question-text-box">
            {
              /* if not blank, show image */
              isImage(question_image_url) && (
                <div className="question-image">
                  <img
                    src={question_image_url}
                    alt={decodeHtml(question_text)}
                  />
                </div>
              )
            }
            <div className="question-text">{decodeHtml(question_text)}</div>
          </div>
          <div className="options-container">
            {aOptions.map((option, index) => (
              <button
                id={option["option_id"]}
                key={index}
                className="sentakushi-btn btn"
                onClick={() => {
                  fRegisterAnswer(option["option_id"]);
                  setOptDescWithDelay(
                    option["option_id"],
                    option["option_description"]
                  ); /* set state to display option description */
                }}
              >
                <table>
                  <tbody>
                    <tr>
                      <td className="option-letter">
                        {decodeHtml(option_letters[index])}
                      </td>
                      <td className="option-text">
                        {decodeHtml(option["option_text"])} 
                        {debug === true && "(" + option["option_value"] + ")"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </button>
            ))}
          </div>
        </div>
      ) : (
        <div
          id="question-box"
          className="col-12 question-box vertical-middle-box option-description-fade-in option-desc-box"
        >
          {/* When user has selected an option, display the option description  */}
          <div id="optionDescTitle" className="option-desc-title option-desc-title-first">
            {decodeHtml(question_description.split('\n')[0])}
          </div>
          <div id="optionDescTitle" className="option-desc-title">
            {decodeHtml(question_description.split('\n')[1])}
          </div>
          {/* Display the explanation for all options regardless of which option was selected */}
          {aOptions.map((option, index) => (
            <div
              key={index}
              id={"optDesc" + option["option_id"]}
              className="question-text-box option-desc-text-box"
            >
              <div
                className={
                  option["option_id"] === selectedOptionId
                    ? "option-desc-text selected"
                    : "option-desc-text"
                }
              >
                {/*�p��ł��ƒ������Ă͂ݏo�Ă��܂��̂Ő܂�Ԃ��悤�ɂ���i�f�t�H���g�͐܂�Ԃ��Ȃ��悤��css�Őݒ肳��Ă���j*/}
                <div style = {lang === "en" ? {"white-space": "normal"} : {}}>
                  {decodeHtml(getFirstRow(option["option_description"]))}
                </div>
                <div>
                  {decodeHtml(getAllButFirstRow(option["option_description"]))}
                </div>
              </div>
            </div>
          ))}
          <div className="next-btn-box">
            <button
              className={fClassLang(["btn", "btn-skinned", "btn-next"], lang)}
              onClick={() => {
                fOptionSelect(); // update the state of the app to proceed to next question
              }}
            ></button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionBox; //export the function component
