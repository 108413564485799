/**
 * MG 2020-06-03
 * This file contains all filenames for images depending on the selected language.
 */


 
export const lang = {
    jp: {
      logo: require("./img/logo_jp.png"),
      uploading_upper: require("./img/uploading_upper_jp.png"),
      uploading_lower: require("./img/uploading_lower_jp.png"),
      tests_completed: require("./img/tests_completed_jp.png"),
      // answer_analysis: require("./img/answer_analysis_jp.png"),
    },
    en: {
      logo: require("./img/logo_en.png"),
      uploading_upper: require("./img/uploading_upper_jp.png"),
      uploading_lower: require("./img/uploading_lower_jp.png"),
      tests_completed: require("./img/tests_completed_en.png"),
      // answer_analysis: require("./img/answer_analysis_en.png"),
    },
    zh: {
      logo: require("./img/logo_zh.png"),
      uploading_upper: require("./img/uploading_upper_zh.png"),
      uploading_lower: require("./img/uploading_lower_jp.png"),
      tests_completed: require("./img/tests_completed_zh.png"),
      // answer_analysis: require("./img/answer_analysis_zh.png"),
    },
    sv: {
      logo: require("./img/logo_en.png"),
      uploading_upper: require("./img/uploading_upper_jp.png"),
      uploading_lower: require("./img/uploading_lower_jp.png"),
      tests_completed: require("./img/tests_completed_en.png"),
      // answer_analysis: require("./img/answer_analysis_en.png"),
    },
};





