import React from "react";
import Modal from "react-bootstrap/Modal";
import ReactDOM from "react-dom";

const PopUp = ({
  modal_title,
  modal_body,
  onClickOK,
  option_id,
  option_value,
  test_id,
  test_index,
  button_label,
  button_classes,
  extra_buttons = [],
}) => {
  const [isOpen, setIsOpen] = React.useState(true);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
    onClickOK(option_id, option_value, test_id, test_index);
  };

  const onButtonClick = () => {
    setIsOpen(false);
    onClickOK(option_id, option_value, test_id, test_index);
  };

  const modalLoaded = () => {
    showModal();
  };

  /** Unmount the popup from the DOM upon exit, removing it completely and resetting all its values. */
  const onExited = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById("popup"));
  };

  return (
    <>
      <Modal
        show={isOpen}
        onHide={hideModal}
        onEntered={modalLoaded}
        onExited={onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modal_title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal_body}</Modal.Body>
        <Modal.Footer>
          {extra_buttons}
          <button className={button_classes} onClick={onButtonClick}>
            {button_label}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PopUp; //export the function component
